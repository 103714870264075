import React from "react"

import HomeLayout from "../components/HomeLayout"
import SEO from "../components/seo"
import Banner from "../components/Banner"
import CardsRolls from '../components/CardsRolls'
import AboutSec from '../components/AboutSec'
import Form from '../components/Form'

const IndexPage = () => (
    <HomeLayout>
      <SEO title="Home" />
      <Banner />
      <CardsRolls />
      <AboutSec id="sobre" />
      <Form />
    </HomeLayout>
)

export default IndexPage
