import styled from 'styled-components'
import media from 'styled-media-query'

export const BannerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    position: absolute;
    top: 0;
`

export const BannerInnerContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 100; 

    margin-right: 50px;

    ${media.lessThan("medium")`
        margin-right: 0;
    `}
`

export const BannerImageContainer = styled.div`
    width: 100%;
    max-width: 550px;

    margin-top: 50px;
    padding-right: 100px;

    ${media.lessThan("medium")`
        display: none;
    `}

`


export const TextContainer = styled.div`
    
    text-align: left;
    min-width: 390px;

    h1 {
        font-family: 'Titillium Web', sans-serif;
        color: #FFF;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
    }

    hr {

        margin-top: 30px;
        margin-bottom: 20px;

        border: none;
        height: 3px;
        background-color: #0C8E86;
        max-width: 80px; 
        margin-left: 0;
    }


    ${media.lessThan("medium")`
        
        text-align: center;
        
        h1 {
            color: #0C8E86;
            font-size: 3.5rem;
        }

        h2 {
            color: #0C8E86;
            font-size: 3.5rem;
        }

        hr {
            max-width: 200px;
            margin-left: auto;
        }

    `}

    ${media.lessThan("small")`

        
        min-width: 250px;

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2.5rem;
        }
    `}

`
export const TextInnerContainer = styled.div`
    overflow: hidden;
    max-height: 8rem;

    h2 {
        max-height: 8rem;
        font-family: 'Titillium Web', sans-serif;
        color: #FFF;
        font-weight: 700;
        font-size: 4rem;
        text-transform: uppercase;

        transition: all 0.4s ease;

        b {
            color: #2A5FAA;
        }
    }

    &.active {
        h2:first-child {
            transform: translateY(8rem);

        }

        h2:nth-child(2) {
            transform: translateY(-8rem)
        }
    }

    ${media.lessThan("medium")`

        max-height: 7rem;

        h2 {
            color: #0C8E86;
            font-size: 3.5rem;
        }

        &.active {
            h2:first-child {
                transform: translateY(7rem);

            }

            h2:nth-child(2) {
                transform: translateY(-7rem)
            }
    }
    `}

    ${media.lessThan("small")`

        margin-top: 10px;
        max-height: 4rem;

        h2 {
            font-size: 2rem;
        }

        &.active {
            h2:first-child {
                transform: translateY(4rem);

            }

            h2:nth-child(2) {
                transform: translateY(-4rem)
            }
    `}
`

export const ButtonsContainer = styled.div`

`

export const AboutButton = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase; 
    color: #FFF;
    letter-spacing: 5px;
    transition: all 0.75s ease;
    cursor: pointer;

    &:hover {
        letter-spacing: 8px;
    }

    a {
        color: #fff;
    }

    div {
        margin-left: 15px;
        display: inline-block;
        width: 13px;
        height: 10px;
        background-color: #0C8E86;
    }

    ${media.lessThan("medium")`
        a {
            color: #0C8E86;
        }
    `}

`

export const ChangeButton = styled.div`

    margin-top: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;

    ${media.lessThan("medium")`
        justify-content: center;
    `}

`

export const Circle = styled.div`

    margin-right: 25px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #FFF;

    cursor: pointer;

    &.active {
        background-color: #0C8E86; 
    }

    ${media.lessThan("medium")`
        background-color: #dbdbdb;

        &:nth-child(2) {
            margin-right: 0px;
        }
    `}
`


export const BannerBox = styled.div`
    width: 70%;
    height: 100%;
    display: block;
    background-color: #182234;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position: absolute;
    z-index: 10;
    right: 0;
    
    border-bottom-left-radius: 50%;

    ${media.lessThan("medium")`
        display: none;
    `}

`