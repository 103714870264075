import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from 'gatsby'

import * as S from "./styled"

const AboutSec = () => {

  const { aboutImages } = useStaticQuery(
    graphql`
      query {
        aboutImages: allFile(filter: {absolutePath: {regex: "/(images)/" }}) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      <S.BackgroundBox img={aboutImages.edges[7].node.childImageSharp.fluid.src} />
      <S.AboutSecContainer>
        <S.Header>
          <h3>coluna</h3>
          <h1>Dr. Darby Lira Tisatto</h1>
          <h2>Especialista em Cirurgia da Coluna</h2>
        </S.Header>
        <S.AboutInnerContainer>
          <S.AboutImageContainer>
            <Img
              fluid={aboutImages.edges[6].node.childImageSharp.fluid}
              alt="Médico"
            />
          </S.AboutImageContainer>
          <S.TextContainer >
            <h6>Apresentação</h6>
            <h4>CRM-RS 37117 <br/> RQE 31132</h4>
            <ul>
              <li>
                <p>- Formado em Medicina pela Universidade de Caxias do Sul-RS (UCS)</p>
              </li>
              <li>
                <p>- Residência médica em Ortopedia e Traumatologia no Hospital Ortopédico/Hospital de Clínicas de Passo Fundo-RS.</p>
              </li>
              <li>
                <p>- Especialização em Cirurgia da Coluna pelo Hospital Ortopédico, também em Passo Fundo-RS.</p>
              </li>
              <li>
                <p>- Membro titular da Sociedade Brasileira de Ortopedia e Traumatologia (SBOT).</p>
              </li>
              <li>
                <p>- Título de especialista pela Sociedade Brasileira de Coluna (SBC).</p>
              </li>      
              <li>
                <p>- Atualmente com atendimentos na cidade de Caxias do Sul.</p>
              </li>      
            </ul>
            <span><Link to="/sobre">Saiba mais</Link></span>
          </S.TextContainer>
        </S.AboutInnerContainer>
      </S.AboutSecContainer>
    </>
  )
}

export default AboutSec