import styled from 'styled-components'
import media from 'styled-media-query'

export const BackgroundBox = styled.div`
    width: 100%;
    height: 1440px;
    display: block;
    background-color: #182234;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position: absolute;
    z-index: 10;
    top: 1070px;
    left: 0;
    
    border-radius: 0 15% 25% 0;

    ${media.lessThan("medium")`
        display: none;
    `}
`

export const AboutSecContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 100;

    margin-top: 80px;
`

export const Header = styled.div`

    font-family: 'Titillium Web', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    h3 {
        font-weight: 700;
        position: absolute;
        z-index: 50;
        font-size: 27rem;
        color: #fff;
        opacity: 0.15;
        display: block;
        letter-spacing: -33.8px;
    }

    h1 {
        margin-top: 80px;
        font-weight: 400;
        font-size: 2.7rem;
        color: #0C8E86;
        position: relative;
        z-index: 100;
    }

    h2 {
        font-weight: 700;
        font-size: 2.7rem;
        color: #fff;
        position: relative;
        z-index: 100;
        padding: 0 5px; 
    }

    ${media.lessThan("medium")`

        text-align: center;

        h3 {
            color: #000;
            opacity: 0.1;
            font-size: 10rem;
            letter-spacing: -3.8px;
        }

        h1 {
            margin-top: 25px;
            font-size: 2rem;

        }

        h2 {
            color: #0C8E86;
            font-size: 1.5rem;

        }
    `}

    ${media.lessThan("small")`

        h3 {

            font-size: 7rem;
            letter-spacing: -3.8px;
        }

        h1 {
            margin-top: 25px;
            font-size: 2rem;

        }

        h2 {

            font-size: 1.5rem;

        }
    `}

`

export const AboutInnerContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 100; 

    ${media.lessThan("large")`
        padding-top: 80px;
    `}
    
    ${media.lessThan("medium")`
        margin-right: 0;
    `}
`

export const AboutImageContainer = styled.div`
    width: 100%;
    max-width: 550px;

    margin-top: 50px;

    

    ${media.lessThan("medium")`
        display: none;
    `}
`

export const TextContainer = styled.div`
    text-align: left;
    width: 510px;
    
    margin-left: 120px;

    font-family: 'Montserrat', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h6 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.2rem;
        font-weight: 700;
        color: #2D60AB;
        text-transform: uppercase;
    }

    h4 {
        font-size: 0.8rem;
        font-weight: 700;
        color: #fff;
        letter-spacing: 6.4px;
        line-height: 1.1rem;
        
        margin: 8px 0 30px 0;
    }

    ul {
        line-height: 33px;

        margin-bottom: 50px;

        li {
            font-size: 0.95rem;
            color: #fff;
        }
    }

    span {
        text-transform: uppercase;
        background-color: #0C8E86;
        font-weight: 700;
        font-size: 0.8rem;
        letter-spacing: 1.4px;
        color: #fff;
        padding: 15px 30px;
        border-radius: 23px;

        transition: all 0.45s ease;

        display: block;

        cursor: pointer;

        a {
            color: #fff;
        }

        &:hover {
            transform: scale(1.07);
            box-shadow: 0px 18px 46px rgba(0,0,0,0.1);
        }
    }

    ${media.lessThan("medium")`
        margin: 0 30px;

        text-align: center;

        h6, h4 {
            width: 100%;
            text-align: center;
        }

        h4 {

        color: #303030;

        }

        ul {
            li {
                color: #303030;
            }
        }

    `}
    
    ${media.lessThan("medium")`
        span {
            align-self: center;
        }
    `}

`