import React, { Component } from "react"
import Img from "gatsby-image"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styled"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CardsSlider extends Component {
  render() {
    
    const settings = {
      dots: false,
      accessibility: true,
      arrows: true,
      infinite: true,
      speed: 500,
      centerPadding: '5px',
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            centerMode: true,
            centerPadding: '5px',
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 1100,
          settings: {
            centerMode: true,
            centerPadding: '5px',
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: '45px',
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '5px',
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    };
    
    CardsSlider.propTypes = {
      posts: PropTypes.array.isRequired,
    }
    
    return (
      <S.CardsContainer {...settings}>
        {
          this.props.posts.map(({node: { id, fields: { slug }, frontmatter: { description, title, highlightTitle, img } }}) => {
            return (
              <Link to={`/doencas${slug}`}>
                <S.Card key={id}>
                  <S.Img>
                    <Img fluid={img.childImageSharp.fluid}  />
                  </S.Img>
                  <h4>{title} <br /><b>{highlightTitle}</b></h4>
                  <hr />
                  <S.CardP>
                    <p>{description}</p>
                  </S.CardP>
                  <span>+</span>
                </S.Card>
              </Link>
            )
          })
        }
      </S.CardsContainer>
    );

  }
}

const CardsRolls = () => {

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query HomeDiseasesList {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(doencas)/"  }}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
              description
              highlightTitle
              title
              img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
            }
          }
        }
      }
    }
  `)

  const data = allMarkdownRemark.edges

  return (
    <>
      <S.Header>
        <h2>Doenças da<br /><b>Coluna</b></h2>
      </S.Header>
      <CardsSlider posts={data} />
    </>
  )
}

export default CardsRolls
