import styled from 'styled-components'
import media from 'styled-media-query'
import Slider from "react-slick";

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    position: relative;
    margin-top: 90vh;

    h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 5px;
        color: #2B5FAB;
        text-align: center;

        b {
            font-family: 'Titillium Web', sans-serif;
            font-size: 3.5rem;
            letter-spacing: 0;
            color: #0C8E86;
        }
    }
`


export const CardsContainer = styled(Slider)`

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding: 30px 100px;

    position: relative;
    z-index: 200;

    div {

        &:focus {
        outline: none;
        border: none;
        }
    }

    button {
        &::before {
            color: #0C8E86;
        }
    }

    a {
        &:focus {
            outline: none;
            border: none;
        }
    }

    .slick-prev {
        left: 30px!important;
    }
    
    .slick-next {
        right: 30px!important;
    }

    
    ${media.lessThan("small")`
        padding: 30px 20px;
    `}

`

export const Card = styled.div`
    flex: 0 1 270px;
    margin: 20px;

    max-width: 270px;
    height: 450px;
    border-radius: 10px;
    background-color: #fff;

    display: flex!important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    position: relative;
    z-index: 200;

    transition: all 0.75s ease;

    cursor: pointer;

    h4 {
        padding: 20px;

        font-family: 'Titillium Web', sans-serif;
        font-size: 1.3rem;
        color: #214191;
        font-weight: 700;
        text-transform: uppercase;

        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 95%;

        b {
            font-size: 1.3rem;
            color: #214191;
            font-weight: 700;
        }
    } 

    hr {
        border: none;
        height: 4px;
        background-color: #0C8E86;
        width: 40%;

        margin-top: 0;
        margin-left: 0;
    }

    a {
        align-self: flex-end;
    }

    span {
        align-self: flex-end;
        text-align: right;
        color: #0C8E86;
        font-size: 1.7rem;
        padding-right: 10px;

    }

    &:hover {
        transform: scale(1.02);
    }

    ${media.lessThan("small")`
        box-shadow: 0px 0px 12px 8px rgba(0,0,0,0.05);
    `}
`

export const Img = styled.div`
    border-radius: 10px 10px 0 0;
    display: block;
    width: 100%;
    height: 150px;
    background-color: #375b93;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;    
    overflow: hidden;
`

export const CardP = styled.div`
    padding: 20px;

    p {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1rem;
        color: #58595B;
        font-weight: 400;
        
        margin: 0 auto;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical; 
        
    }
`
