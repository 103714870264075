import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import * as S from "./styled"

const Banner = () => {
  const { bannerImages } = useStaticQuery(
    graphql`
      query {
        bannerImages: allFile(filter: {absolutePath: {regex: "/(images)/"  }}) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  const [bannerText, setBannerText] = useState(true)

  useEffect(() => {
    const textChange = setInterval(() => {
      setBannerText(!bannerText)
    }, 8000)
    return () => clearInterval(textChange)
  })

  return (
    <S.BannerContainer>
      <S.BannerInnerContainer>
        <S.BannerImageContainer>
          <Img
            fluid={bannerImages.edges[5].node.childImageSharp.fluid}
            alt="Médico"
          />
        </S.BannerImageContainer>
        <S.TextContainer >
          <h1>
            Darby <br />
            Tisatto
          </h1>
          <S.TextInnerContainer className={bannerText ? "" : "active"}>
            <h2>
              Cirurgia<br /> 
              da<b> Coluna</b>
            </h2>
            <h2>
              ORTOPEDIA<br />
              <b>traumatologia</b></h2>
          </S.TextInnerContainer>
          <hr />
          <S.ButtonsContainer>
            <S.AboutButton>
              <Link to="/sobre">
                <span>Saiba mais</span>
                <div />
              </Link>
            </S.AboutButton>
            <S.ChangeButton>
              <S.Circle
                className={bannerText ? "active" : ""}
                onClick={() => setBannerText(!bannerText)}
              />
              <S.Circle
                className={bannerText ? "" : "active"}
                onClick={() => setBannerText(!bannerText)}
              />
            </S.ChangeButton>
          </S.ButtonsContainer>
        </S.TextContainer>
      </S.BannerInnerContainer>
      <S.BannerBox
        img={bannerImages.edges[3].node.childImageSharp.fluid.src}
      ></S.BannerBox>
    </S.BannerContainer>
  )
}

export default Banner
